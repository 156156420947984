import { FormatIds } from './reactIntl';

export enum CompanyDashboardViewType {
	analytics = 'analytics',
	spots = 'spots',
}

export enum AnalyticsSearchOption {
	last30Days = 'last30Days',
	last3Months = 'last3Months',
	last6Months = 'last6Months',
	last12Months = 'last12Months',
}

export const DROPDOWN_SELECT_OPTIONS = [
	{
		value: AnalyticsSearchOption.last30Days,
		label: 'TableFilter.Date.Last30.Days',
	},
	{
		value: AnalyticsSearchOption.last3Months,
		label: 'TableFilter.Date.Last3.Months',
	},
	{
		value: AnalyticsSearchOption.last6Months,
		label: 'TableFilter.Date.Last6.Months',
	},
	{
		value: AnalyticsSearchOption.last12Months,
		label: 'TableFilter.Date.Last12.Months',
	},
];

export const ViewTypeTitle: Record<CompanyDashboardViewType, FormatIds> = {
	[CompanyDashboardViewType.analytics]: 'CompanyDashboard.Header.Menu.Analytics.Title',
	[CompanyDashboardViewType.spots]: 'CompanyDashboard.Header.Menu.ActionRequired.Title',
};

export enum FilterID {
	ASSET_FOLDERS = 'assetFolders',
	ASSET = 'asset',
	REPORTER = 'reporter',
}

export const FILTER_TRANSLATIONS: Record<FilterID, FormatIds> = {
	[FilterID.ASSET_FOLDERS]: 'CompanyDashboard.Filter.AssetFolders',
	[FilterID.ASSET]: 'App.Asset',
	[FilterID.REPORTER]: 'App.Reporter',
};

export interface FilterResponse {
	data: {
		id: string;
		name: string;
		options: { text: string }[];
	}[];
}

export type SpotTrendDataType = Record<string, number | string>[];

export type ChartDataItemType = {
	name: string;
	value: number;
	color: string;
};

export type ChartDataType = ChartDataItemType[];

export type LegendDataType = {
	name: string;
	color: string;
}[];

export type CompanyDashboardDataType = {
	spotDistributionChartData: ChartDataType;
	spotTrendChartData: SpotTrendDataType;
	statusChartData: ChartDataType;
	severityChartData: ChartDataType;
	tilesData: DashboardTileValueType;
};

// type for the values of each tile
export type DashboardTileValueType = Record<string, number>;

// type for content of each tile
export type DashBoardTileData = {
	title: FormatIds;
	tooltipText?: FormatIds;
};

export enum DashboardTiles {
	OPEN_SPOTS = 'Open Spots',
	MAJOR_SEVERITY_SPOTS = 'Major Severity Spots',
	OVERDUE_SPOTS = 'Overdue Spots',
	DUE_SPOTS = 'Due Spots',
}

export type CompanyDashboardSpotType = {
	projectName: string;
	projectID: string;
	topicID: string;
	ticketID: number;
	dueDate: string;
	attributes: {
		reporter: number;
		status: number;
		severity: string;
		defectType: string;
	};
};
