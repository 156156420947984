import { LanguageOptions } from '../../localization';
import { AnnouncementType, AnnouncementVisibility } from '../../tstypes/appConfig';

interface SessionStorageItems {
	eagleIDState?: string;
	accessToken?: string;
	landingPathname?: string;
	landingQuery?: string;
	appVersion?: string;
	codeVerifier?: string;
}

export interface UISettings {
	userID: number;
	recentColorCollection: string[];
	recentStrokeColorCollection: string[];
	spotMediaSortPreference: string;
}

export interface TutorialSettings {
	createFirstAsset: boolean;
	createSpotOnTwoDView: boolean;
	showCreateTwoDView: boolean;
}

interface LocalStorageItems {
	language?: LanguageOptions;
	devFeatureConfig?: string;
	injectToken?: 'true' | 'false';
	mapType?: 'satellite' | 'hybrid' | 'standard';
	UISettings?: UISettings;
	announcements?: { [key in AnnouncementType]: AnnouncementVisibility };
	tutorialSettings?: TutorialSettings;
	homePageEmail?: string; // to save 'email' query param from /home page
}

export function getSessionItem(key: keyof SessionStorageItems): string | null {
	return sessionStorage.getItem(key);
}

export function saveSessionItem(key: keyof SessionStorageItems, value: string) {
	sessionStorage.setItem(key, value);
}

export function removeSessionItem(key: keyof SessionStorageItems) {
	sessionStorage.removeItem(key);
}

export function getLocalItem(key: keyof LocalStorageItems): string | null {
	return localStorage.getItem(key);
}

export function saveLocalItem(key: keyof LocalStorageItems, value: string) {
	localStorage.setItem(key, value);
}

export function removeLocalItem(key: keyof LocalStorageItems) {
	localStorage.removeItem(key);
}
