import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import localeData from 'dayjs/plugin/localeData';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

export default function initDayjsExtensions() {
	dayjs.extend(utc);
	dayjs.extend(weekday);
	dayjs.extend(duration);
	dayjs.extend(timezone);
	dayjs.extend(localeData);
	dayjs.extend(weekOfYear);
	dayjs.extend(relativeTime);
	dayjs.extend(updateLocale);
	dayjs.extend(LocalizedFormat);
}
